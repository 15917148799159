.wrapper {
    flex-wrap: nowrap;
    cursor: pointer;
}

.wrapper:hover .card {
    background-color: var(--ht-color-bg-primary-800);
}

.badge {
    border-radius: var(--border-radius-circle);
    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 0;
    height: var(--font-size-2x-small);
    margin-top: var(--spacing-small);
    visibility: hidden;
    width: var(--font-size-2x-small);
    transition: background-color var(--transition-fast),
        visibility var(--transition-fast);
}

.badge.show {
    background-color: var(--ht-color-bg-primary);
    visibility: visible;
}

.card {
    border-radius: var(--border-radius-large);
    border: var(--border-thin) solid var(--ht-color-variant-neutral);
    flex-grow: 1;
    height: max-content;
    padding: var(--spacing-small);
    transition: background-color var(--transition-fast);
}

.card a {
    color: var(--ht-color-bg-primary);
    font-weight: var(--font-weight-semibold);
}

.card a:hover {
    text-decoration: underline;
}

.title {
    font-size: var(--font-size-x-large);
}
.date {
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-medium);
}

.body {
    font-size: var(--font-size-medium);
}

.body * {
    list-style-position: inside;
}

.body ul,
.body ol {
    padding: var(--spacing-small);
}
