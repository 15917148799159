@use '../../styles/scss/global/index.scss' as *;

.settings {
    &-fetched-list {
        --list-item-width: #{vw(400px)};
        --list-item-height: #{vw(350px)};
        --list-item-name-size: #{vw(30px)};
        --field-input-error-size: #{vw(13px)};
        --popup-content-title-size: #{vw(58px)};
        --popup-content-description-size: #{vw(38px)};
        --popup-close-btn-size: 2rem;

        display: flex;
        flex-wrap: wrap;
        grid-gap: var(--s3);
    }

    .upvote-popup {
        background-color: var(--ht-color-bg-primary);
        color: var(--ht-color-text-primary);
    }
}
