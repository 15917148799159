.not-found {
    --not-found-title-size: #{'max(2.5rem, min(10vw, 10rem))'};
    --not-found-description-size: #{'max(1.8rem, min(4vw, 4rem))'};

    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    align-content: center;

    background: #3d7d99;
    color: white;

    &__title {
        font-size: var(--not-found-title-size);
        line-height: 1;
        margin: 0.2em 0;
    }

    &__description {
        font-size: var(--not-found-description-size);
        line-height: 1;
        margin: 0.2em 0;
    }

    &__cta {
        display: block;
        margin: 0.5em 0;

        text-decoration: none;
        color: inherit;

        border: 1px solid currentColor;
        padding: 0.5em 1em;
    }
}
