.wrapper {
    padding: var(--spacing-x-small);
    position: relative;
    width: var(--s5);
    z-index: var(--z-index-start);
}

.hide {
    display: none;
}

.background {
    border-radius: var(--border-radius-large);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: var(--z-index-neg-start);
}

.wrapper:hover .background {
    background-color: var(--ht-color-text-primary-600);
    opacity: var(--opacity-60);
    transition: background-color var(--transition-normal);
}

.wrapper:hover .item .icon,
.wrapper .item.add .icon {
    color: var(--ht-color-text-primary);
}

.wrapper:hover .item img {
    background-color: var(--ht-color-text-primary);
}

.item {
    align-items: center;
    cursor: pointer;
    z-index: var(--z-index-start);
}

.item img {
    background-color: var(--ht-color-bg-primary-900);
    border-radius: var(--border-radius-large);
    max-width: var(--s4);
    min-height: 41px;
    min-width: 41px;
    padding: var(--spacing-2x-small);
    transition: background-color var(--transition-normal);
    width: 70%;
}

.item .icon {
    color: var(--ht-color-text-primary-800);
    display: grid;
    font-size: var(--s3);
    margin: 0 auto;
    place-content: center;
    transition: color var(--transition-normal);
}

.item .icon ~ .title {
    margin-top: calc(var(--s0) + (-3.5px));
}

.item .icon svg {
    padding-top: 2px;
    transform: scale(var(--scale-1));
}

.title {
    color: inherit;
    font-size: var(--font-size-x-small);
    font-weight: var(--font-weight-bold);
    margin-top: var(--spacing-2x-small);
    text-align: center;
    text-shadow: var(--shadow-small);
    width: 100%;
}

.close {
    background-color: var(--ht-color-text-primary);
    border-radius: 50%;
    color: var(--ht-color-bg-primary);
    cursor: pointer;
    opacity: var(--opacity-0);
    padding: var(--spacing-2x-small);
    position: absolute;
    right: calc(var(--s-4) * -1);
    top: calc(var(--s-4) * -1);
    z-index: var(--z-index-tooltip);
}

.wrapper:hover .close {
    opacity: var(--opacity-100);
}
