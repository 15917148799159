@use '../../styles/scss/global/index.scss' as *;

.settings {
    --settings-group-title-size: var(--s2);
    background-color: var(--white);
    z-index: 100;
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    display: flex;
    justify-content: space-between;

    &__tabs {
        width: 45%;
    }

    &__content {
        width: 80%;
        height: 100%;
        padding-top: 5%;
        padding-left: 8%;

        &__group {
            margin: 2em 0;
            .group__title {
                font-size: var(--settings-group-title-size);
                text-transform: capitalize;
                color: var(--primaryBlue);
                margin: 0.5em 0;
            }
        }
    }
}

@media (max-width: $tablet) {
    .settings {
        --settings-group-title-size: #{vw-tablet(20px)};
    }
}

@media (max-width: $mobile) {
    .settings {
        --settings-group-title-size: #{vw-mobile(20px)};
    }
}
