.search {
    margin-top: var(--spacing-2x-small);
    width: 100%;
}

.field {
    --input-placeholder-color: var(--ht-color-text-primary);
    --input-label-color: var(--ht-color-text-primary);
    align-items: center;
    background-color: rgba(255, 255, 255, 0.22);
    border-radius: var(--border-radius-large);
    box-shadow: var(--shadow-x-large);
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    gap: var(--s-3);
    padding: 0 var(--spacing-x-small);
    transition: background-color var(--transition-normal) box-shadow
        var(--transition-normal);
    width: 100%;
}

.field:active,
.field:hover,
.field:not(:has(.input:placeholder-shown)) {
    background-color: var(--ht-color-text-primary);
    box-shadow: var(--focus-ring);
    border-color: transparent;
    --input-placeholder-color: currentColor;
    --input-label-color: var(--ht-color-bg-primary);
}

.field:focus input,
.field:focus-visible input,
.field:focus-within input {
    caret-color: var(--input-placeholder-color);
}

.input {
    background-color: transparent;
    border: none;
    color: inherit;
    font-size: var(--font-size-medium);
    outline: none;
    padding: var(--spacing-small);
    width: inherit;
}

.input::placeholder {
    color: var(--input-placeholder-color);
}

.label {
    background-color: transparent;
    color: var(--ht-color-bg-primary);
    color: var(--input-label-color);
    cursor: pointer;
    filter: drop-shadow(var(--shadow-large));
    font-size: var(--s1);
    padding: 0 var(--spacing-2x-small);
}
