@use '../../styles/scss/global/index.scss' as *;

.bookmark-item {
    --bookmarker-icon-size: #{vw(55px)};
    --bookmarker-width: #{vw(40px)};

    width: var(--bookmarker-width);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    margin: 0.8em 0;
    text-decoration: none;

    &:hover {
        .bookmark-item-delete {
            visibility: visible;
            opacity: 1;
            outline: none;
        }
    }

    &-delete {
        position: absolute;
        top: -20%;
        right: -15%;

        display: grid;
        place-items: center;

        width: calc(var(--bookmarker-icon-size) / 2);
        height: calc(var(--bookmarker-icon-size) / 2);
        padding: 0;

        background: var(--white);
        color: var(--color-outline);

        opacity: 0;
        visibility: hidden;

        border: none;
        cursor: pointer;

        @include set-transition(
            (opacity, visibility),
            (0.2s, 0.1s),
            (ease-in-out, ease)
        );
        @include circle-radius();
    }

    &-icon {
        display: grid;
        place-items: center;
        width: var(--bookmarker-icon-size);
        height: var(--bookmarker-icon-size);
        position: relative;

        img {
            width: 100%;
            height: 100%;
            outline: none;
            border: none;
            padding: 20%;
            background: white;
            @include circle-radius();
        }

        @include circle-radius();
    }

    &-title {
        width: 96px;
        margin: 0.5em 0;
        font-size: 0.8rem;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        line-height: 20px;
        white-space: pre;
        color: white;
        text-decoration: none;

        @include drop-shadow();
    }
}

@media (max-width: $tablet) {
    .bookmark-item {
        --bookmarker-icon-size: #{vw-tablet(55px)};
    }
}

@media (max-width: $mobile) {
    .bookmark-item {
        --bookmarker-icon-size: #{vw-mobile(55px)};
    }
}
