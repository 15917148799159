.logo {
    &__parent {
        position: absolute;
        top: 0;
        left: 5%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: #{'max(5rem, min(10vw, 10rem))'};
        color: var(--primaryBlue);
        user-select: none;
        transform: scale(0.5);

        .logo {
            width: 90%;
            height: 90%;
            user-select: none;
        }

        .logo__text {
            font-size: #{'max(2rem, min(5.5vw, 6rem))'};
            font-family: 'proxima-nova';
            font-weight: 600;
            opacity: 0.9;
            margin: 0 0.1em;
            line-height: 0.7;
            user-select: none;
        }
    }
}
