html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

body {
    background-color: var(--ht-color-text-primary);
    color: var(--ht-color-nice-black);
}

.layout {
    background-color: var(--ht-color-bg-primary);
    color: var(--ht-color-text-primary);
}

.layout .layout-2 {
    background-color: var(--ht-color-text-primary);
    color: var(--ht-color-nice-black);
}

:root {
    line-height: var(--ratio);
    font-size: 80%;
}
