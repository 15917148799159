@use '../../styles/scss/global/index.scss' as *;

div.card {
    --card-width: calc(var(--measure) / var(--scale-1));
    --card-height: calc(var(--measure) / var(--scale-2));
    --avatar-size: calc(var(--s5) + var(--s2));

    background-color: var(--ht-color-variant-neutral-800);
    border-radius: var(--border-radius-large);
    border: var(--border-thin) solid var(--ht-color-variant-neutral-600);
    box-shadow: var(--shadow-large);
    color: var(--ht-color-nice-black);
    display: grid;
    grid-row-gap: var(--s2);
    height: 100%;
    padding-bottom: var(--spacing-3x-large);
    padding: var(--spacing-x-large);
    width: var(--card-width);

    .body {
        align-items: flex-start;
        display: flex;
        grid-column-gap: var(--s0);

        .pointer {
            cursor: pointer;
        }

        .avatar {
            background-color: var(--ht-color-dark-grey-950);
            border-radius: var(--border-radius-circle);
            border: var(--border-thin) solid var(--ht-color-dark-grey-600);
            height: var(--avatar-size);
            object-fit: contain;
            padding: var(--spacing-2x-small);
            width: var(--avatar-size);
        }

        .texts {
            display: grid;
            grid-row-gap: var(--spacing-x-small);
        }

        .name {
            font-size: var(--s1);
        }

        .description {
            align-items: flex-start;
            display: grid;
            font-size: var(--s0);
            grid-row-gap: var(--spacing-x-small);
            max-height: 100px;
            transition: min-height var(--transition-normal) ease;

            .moreBtn {
                background-color: var(--ht-color-variant-neutral-200);
                border-radius: var(--border-radius-small);
                border: none;
                color: var(--ht-color-text-primary);
                cursor: pointer;
                justify-self: flex-end;
                padding: var(--spacing-2x-small) var(--spacing-medium);
            }
        }
    }

    .footer {
        align-items: center;
        display: flex;
        grid-column-gap: var(--spacing-x-small);

        .field {
            align-items: center;
            display: flex;
            flex-direction: column;
            margin: 0.5em 0;
            position: relative;
            width: 100%;

            .error {
                bottom: -55%;
                color: var(--ht-color-variant-danger);
                font-size: var(--field-input-error-size);
                opacity: 0;
                position: absolute;
                width: 100%;

                &.shown {
                    opacity: 1;

                    & ~ .field-input {
                        border-color: var(--ht-color-variant-danger);

                        &:active,
                        &:focus,
                        &:focus-visible,
                        &:focus-within {
                            border-bottom-color: var(--color-error);
                            border-color: transparent;
                        }
                    }
                }
                transition: opacity 0.4s ease;
            }
            .input {
                border-bottom-width: 3px;
                border-radius: var(--border-radius-small);
                border: 1px solid var(--color-outline);
                margin: 0 auto;
                outline: none;
                padding: 0.5em 0.2em;
                width: 100%;

                &.valid {
                    border-color: var(--color-valid);
                }

                &:active,
                &:focus,
                &:focus-visible,
                &:focus-within {
                    border-bottom-color: var(--color-valid);
                    border-color: transparent;
                }
                transition: border-color 0.4s ease;
            }
        }

        .upvote {
            align-items: center;
            background: var(--color-outline);
            border-radius: var(--border-radius-small);
            border: none;
            color: var(--white);
            cursor: pointer;
            display: flex;
            justify-content: center;
            padding: 0.5em 2em;
            text-align: center;

            .rewards {
                align-items: center;
                display: flex;
                justify-content: center;
                margin: 0 0.2em;
                position: relative;
            }
            .icon {
                svg {
                    fill: var(--color-valid);
                }
            }
        }
    }
}

.popup {
    text-align: left;
}
