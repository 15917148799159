.popup {
    height: 100vh;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100vw;
    z-index: var(--z-index-popup);
}

.popup.open {
    opacity: 1;
    visibility: visible;
}

.overview {
    background: rgba(0, 0, 0, 0.3);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.closeBtn {
    position: absolute;
    top: 5%;
    right: 5%;
    cursor: pointer;
    color: var(--black);
    font-size: var(--popup-close-btn-size);
    transition: transform var(--transition-normal);
}

.closeBtn:hover,
.closeBtn:active,
.closeBtn:focus,
.closeBtn:focus-visible,
.closeBtn:focus-within {
    transform: scale(1.3);
}

stack-l.content {
    background: var(--ht-color-text-primary);
    box-shadow: var(--shadow-large);
    color: var(--ht-color-variant-neutral-50);
    max-height: 60%;
    max-width: 65%;
    min-width: 45%;
    overflow-y: scroll;
    padding: var(--spacing-2x-large);
    position: relative;
    transition: opacity var(--transition-normal),
        visibility var(--transition-fast);
}
