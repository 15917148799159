:root {
    /* light colors */
    --ht-color-nice-blue-50: hsl(199.35, 43.66%, 13.92%);
    --ht-color-nice-blue-100: hsl(198.37, 42.61%, 22.55%);
    --ht-color-nice-blue-200: hsl(198, 43.21%, 31.76%);
    --ht-color-nice-blue-300: hsl(198.35, 43.15%, 38.63%);
    --ht-color-nice-blue-400: hsl(198.29, 33.33%, 48.24%);
    --ht-color-nice-blue-500: hsl(198.1, 31.03%, 60.2%);
    --ht-color-nice-blue-600: hsl(198.26, 31.51%, 71.37%);
    --ht-color-nice-blue-700: hsl(198.75, 31.37%, 80%);
    --ht-color-nice-blue-800: hsl(197.14, 30.43%, 86.47%);
    --ht-color-nice-blue-900: hsl(195, 30%, 92.16%);
    --ht-color-nice-blue-950: hsl(195, 33.33%, 97.65%);
    --ht-color-nice-blue: hsl(198.26, 42.99%, 41.96%);

    --ht-color-nice-white-50: hsl(0, 0%, 15.29%);
    --ht-color-nice-white-100: hsl(0, 0%, 24.71%);
    --ht-color-nice-white-200: hsl(0, 0%, 34.9%);
    --ht-color-nice-white-300: hsl(0, 0%, 42.35%);
    --ht-color-nice-white-400: hsl(0, 0%, 51.76%);
    --ht-color-nice-white-500: hsl(0, 0%, 62.75%);
    --ht-color-nice-white-600: hsl(0, 0%, 72.94%);
    --ht-color-nice-white-700: hsl(0, 0%, 80.78%);
    --ht-color-nice-white-800: hsl(0, 0%, 87.06%);
    --ht-color-nice-white-900: hsl(0, 0%, 92.55%);
    --ht-color-nice-white-950: hsl(0, 0%, 97.65%);
    --ht-color-nice-white: hsl(0, 0%, 100%);

    --ht-color-nice-black-50: hsl(0, 0%, 15.29%);
    --ht-color-nice-black-100: hsl(0, 0%, 24.71%);
    --ht-color-nice-black-200: hsl(0, 0%, 34.9%);
    --ht-color-nice-black-300: hsl(0, 0%, 42.35%);
    --ht-color-nice-black-400: hsl(0, 0%, 51.76%);
    --ht-color-nice-black-500: hsl(0, 0%, 62.75%);
    --ht-color-nice-black-600: hsl(0, 0%, 72.94%);
    --ht-color-nice-black-700: hsl(0, 0%, 80.78%);
    --ht-color-nice-black-800: hsl(0, 0%, 87.06%);
    --ht-color-nice-black-900: hsl(0, 0%, 92.55%);
    --ht-color-nice-black-950: hsl(0, 0%, 97.65%);
    --ht-color-nice-black: hsl(0, 0%, 16.08%);

    --ht-color-nice-red-50: hsl(336.59, 45.05%, 17.84%);
    --ht-color-nice-red-100: hsl(337.61, 45.58%, 28.82%);
    --ht-color-nice-red-200: hsl(336.63, 46.34%, 40.2%);
    --ht-color-nice-red-300: hsl(336.84, 45.97%, 48.63%);
    --ht-color-nice-red-400: hsl(337.06, 65.38%, 59.22%);
    --ht-color-nice-red-500: hsl(337.2, 65.79%, 70.2%);
    --ht-color-nice-red-600: hsl(337.18, 66.36%, 79.02%);
    --ht-color-nice-red-700: hsl(336.73, 65.33%, 85.29%);
    --ht-color-nice-red-800: hsl(336.36, 64.71%, 90%);
    --ht-color-nice-red-900: hsl(336.67, 64.29%, 94.51%);
    --ht-color-nice-red-950: hsl(340, 75%, 98.43%);
    --ht-color-nice-red: hsl(337, 66%, 59%);

    --ht-color-nice-yellow-50: hsl(38.18, 73.33%, 97.06%);
    --ht-color-nice-yellow-100: hsl(39.47, 73.08%, 89.8%);
    --ht-color-nice-yellow-200: hsl(38.51, 73.63%, 82.16%);
    --ht-color-nice-yellow-300: hsl(39.39, 72.26%, 73.14%);
    --ht-color-nice-yellow-400: hsl(39.31, 72.86%, 60.98%);
    --ht-color-nice-yellow-500: hsl(39.51, 66.13%, 48.63%);
    --ht-color-nice-yellow-600: hsl(39.56, 65.85%, 40.2%);
    --ht-color-nice-yellow-700: hsl(39.11, 66.67%, 32.94%);
    --ht-color-nice-yellow-800: hsl(39.78, 66.67%, 27.06%);
    --ht-color-nice-yellow-900: hsl(38.77, 65.66%, 19.41%);
    --ht-color-nice-yellow-950: hsl(39, 66.67%, 11.76%);
    --ht-color-nice-yellow: hsl(39, 73%, 52%);

    --ht-color-valid-green-50: hsl(148.97, 49.15%, 11.57%);
    --ht-color-valid-green-100: hsl(148.09, 48.45%, 19.02%);
    --ht-color-valid-green-200: hsl(148.18, 48.53%, 26.67%);
    --ht-color-valid-green-300: hsl(148.1, 47.88%, 32.35%);
    --ht-color-valid-green-400: hsl(147.84, 48.26%, 39.41%);
    --ht-color-valid-green-500: hsl(148.21, 48.15%, 47.65%);
    --ht-color-valid-green-600: hsl(148, 59.47%, 55.49%);
    --ht-color-valid-green-700: hsl(148.17, 68.05%, 66.86%);
    --ht-color-valid-green-800: hsl(148, 68.81%, 78.63%);
    --ht-color-valid-green-900: hsl(147.8, 69.49%, 88.43%);
    --ht-color-valid-green-950: hsl(150, 66.67%, 96.47%);
    --ht-color-valid-green: hsl(147.92, 68.57%, 58.82%);

    --ht-color-error-orange-50: hsl(20, 71.05%, 14.9%);
    --ht-color-error-orange-100: hsl(19.53, 70.49%, 23.92%);
    --ht-color-error-orange-200: hsl(19.83, 70.76%, 33.53%);
    --ht-color-error-orange-300: hsl(20, 71.01%, 40.59%);
    --ht-color-error-orange-400: hsl(19.55, 53.23%, 51.37%);
    --ht-color-error-orange-500: hsl(19.8, 53.19%, 63.14%);
    --ht-color-error-orange-600: hsl(20, 53.73%, 73.73%);
    --ht-color-error-orange-700: hsl(20.4, 53.19%, 81.57%);
    --ht-color-error-orange-800: hsl(20, 52.38%, 87.65%);
    --ht-color-error-orange-900: hsl(21, 55.56%, 92.94%);
    --ht-color-error-orange-950: hsl(24, 45.45%, 97.84%);
    --ht-color-error-orange: hsl(19.74, 70.78%, 42.94%);

    --ht-color-light-grey-50: hsl(0, 0%, 15.29%);
    --ht-color-light-grey-100: hsl(0, 0%, 24.71%);
    --ht-color-light-grey-200: hsl(0, 0%, 34.9%);
    --ht-color-light-grey-300: hsl(0, 0%, 42.35%);
    --ht-color-light-grey-400: hsl(0, 0%, 51.76%);
    --ht-color-light-grey-500: hsl(0, 0%, 62.75%);
    --ht-color-light-grey-600: hsl(0, 0%, 72.94%);
    --ht-color-light-grey-700: hsl(0, 0%, 80.78%);
    --ht-color-light-grey-800: hsl(0, 0%, 87.06%);
    --ht-color-light-grey-900: hsl(0, 0%, 92.55%);
    --ht-color-light-grey-950: hsl(0, 0%, 97.65%);
    --ht-color-light-grey: hsl(0, 0%, 85.88%);

    --ht-color-dark-grey-50: hsl(0, 0%, 15.29%);
    --ht-color-dark-grey-100: hsl(0, 0%, 24.71%);
    --ht-color-dark-grey-200: hsl(0, 0%, 34.9%);
    --ht-color-dark-grey-300: hsl(0, 0%, 42.35%);
    --ht-color-dark-grey-400: hsl(0, 0%, 51.76%);
    --ht-color-dark-grey-500: hsl(0, 0%, 62.75%);
    --ht-color-dark-grey-600: hsl(0, 0%, 72.94%);
    --ht-color-dark-grey-700: hsl(0, 0%, 80.78%);
    --ht-color-dark-grey-800: hsl(0, 0%, 87.06%);
    --ht-color-dark-grey-900: hsl(0, 0%, 92.55%);
    --ht-color-dark-grey-950: hsl(0, 0%, 97.65%);
    --ht-color-dark-grey: hsl(0, 0%, 69.41%);
}

:root {
    --ht-color-bg-primary-50: var(--ht-color-nice-blue-50);
    --ht-color-bg-primary-100: var(--ht-color-nice-blue-100);
    --ht-color-bg-primary-200: var(--ht-color-nice-blue-200);
    --ht-color-bg-primary-300: var(--ht-color-nice-blue-300);
    --ht-color-bg-primary-400: var(--ht-color-nice-blue-400);
    --ht-color-bg-primary-500: var(--ht-color-nice-blue-500);
    --ht-color-bg-primary-600: var(--ht-color-nice-blue-600);
    --ht-color-bg-primary-700: var(--ht-color-nice-blue-700);
    --ht-color-bg-primary-800: var(--ht-color-nice-blue-800);
    --ht-color-bg-primary-900: var(--ht-color-nice-blue-900);
    --ht-color-bg-primary-950: var(--ht-color-nice-blue-950);
    --ht-color-bg-primary: var(--ht-color-nice-blue);

    --ht-color-text-primary-50: var(--ht-color-nice-white-50);
    --ht-color-text-primary-100: var(--ht-color-nice-white-100);
    --ht-color-text-primary-200: var(--ht-color-nice-white-200);
    --ht-color-text-primary-300: var(--ht-color-nice-white-300);
    --ht-color-text-primary-400: var(--ht-color-nice-white-400);
    --ht-color-text-primary-500: var(--ht-color-nice-white-500);
    --ht-color-text-primary-600: var(--ht-color-nice-white-600);
    --ht-color-text-primary-700: var(--ht-color-nice-white-700);
    --ht-color-text-primary-800: var(--ht-color-nice-white-800);
    --ht-color-text-primary-900: var(--ht-color-nice-white-900);
    --ht-color-text-primary-950: var(--ht-color-nice-white-950);
    --ht-color-text-primary: var(--ht-color-nice-white);

    --ht-color-text-secondary-50: var(--ht-color-valid-green-50);
    --ht-color-text-secondary-100: var(--ht-color-valid-green-100);
    --ht-color-text-secondary-200: var(--ht-color-valid-green-200);
    --ht-color-text-secondary-300: var(--ht-color-valid-green-300);
    --ht-color-text-secondary-400: var(--ht-color-valid-green-400);
    --ht-color-text-secondary-500: var(--ht-color-valid-green-500);
    --ht-color-text-secondary-600: var(--ht-color-valid-green-600);
    --ht-color-text-secondary-700: var(--ht-color-valid-green-700);
    --ht-color-text-secondary-800: var(--ht-color-valid-green-800);
    --ht-color-text-secondary-900: var(--ht-color-valid-green-900);
    --ht-color-text-secondary-950: var(--ht-color-valid-green-950);
    --ht-color-text-secondary: var(--ht-color-valid-green);
}

:root {
    --ht-color-variant-primary-50: var(--ht-color-nice-blue-50);
    --ht-color-variant-primary-100: var(--ht-color-nice-blue-100);
    --ht-color-variant-primary-200: var(--ht-color-nice-blue-200);
    --ht-color-variant-primary-300: var(--ht-color-nice-blue-300);
    --ht-color-variant-primary-400: var(--ht-color-nice-blue-400);
    --ht-color-variant-primary-500: var(--ht-color-nice-blue-500);
    --ht-color-variant-primary-600: var(--ht-color-nice-blue-600);
    --ht-color-variant-primary-700: var(--ht-color-nice-blue-700);
    --ht-color-variant-primary-800: var(--ht-color-nice-blue-800);
    --ht-color-variant-primary-900: var(--ht-color-nice-blue-900);
    --ht-color-variant-primary-950: var(--ht-color-nice-blue-950);
    --ht-color-variant-primary: var(--ht-color-nice-blue);

    --ht-color-variant-success-50: var(--ht-color-valid-green-50);
    --ht-color-variant-success-100: var(--ht-color-valid-green-100);
    --ht-color-variant-success-200: var(--ht-color-valid-green-200);
    --ht-color-variant-success-300: var(--ht-color-valid-green-300);
    --ht-color-variant-success-400: var(--ht-color-valid-green-400);
    --ht-color-variant-success-500: var(--ht-color-valid-green-500);
    --ht-color-variant-success-600: var(--ht-color-valid-green-600);
    --ht-color-variant-success-700: var(--ht-color-valid-green-700);
    --ht-color-variant-success-800: var(--ht-color-valid-green-800);
    --ht-color-variant-success-900: var(--ht-color-valid-green-900);
    --ht-color-variant-success-950: var(--ht-color-valid-green-950);
    --ht-color-variant-success: var(--ht-color-valid-green);

    --ht-color-variant-danger-50: var(--ht-color-error-orange-50);
    --ht-color-variant-danger-100: var(--ht-color-error-orange-100);
    --ht-color-variant-danger-200: var(--ht-color-error-orange-200);
    --ht-color-variant-danger-300: var(--ht-color-error-orange-300);
    --ht-color-variant-danger-400: var(--ht-color-error-orange-400);
    --ht-color-variant-danger-500: var(--ht-color-error-orange-500);
    --ht-color-variant-danger-600: var(--ht-color-error-orange-600);
    --ht-color-variant-danger-700: var(--ht-color-error-orange-700);
    --ht-color-variant-danger-800: var(--ht-color-error-orange-800);
    --ht-color-variant-danger-900: var(--ht-color-error-orange-900);
    --ht-color-variant-danger-950: var(--ht-color-error-orange-950);
    --ht-color-variant-danger: var(--ht-color-error-orange);

    --ht-color-variant-neutral-50: var(--ht-color-dark-grey-50);
    --ht-color-variant-neutral-100: var(--ht-color-dark-grey-100);
    --ht-color-variant-neutral-200: var(--ht-color-dark-grey-200);
    --ht-color-variant-neutral-300: var(--ht-color-dark-grey-300);
    --ht-color-variant-neutral-400: var(--ht-color-dark-grey-400);
    --ht-color-variant-neutral-500: var(--ht-color-dark-grey-500);
    --ht-color-variant-neutral-600: var(--ht-color-dark-grey-600);
    --ht-color-variant-neutral-700: var(--ht-color-dark-grey-700);
    --ht-color-variant-neutral-800: var(--ht-color-dark-grey-800);
    --ht-color-variant-neutral-900: var(--ht-color-dark-grey-900);
    --ht-color-variant-neutral-950: var(--ht-color-dark-grey-950);
    --ht-color-variant-neutral: var(--ht-color-dark-grey);

    --ht-color-variant-warning-50: var(--ht-color-nice-yellow-50);
    --ht-color-variant-warning-100: var(--ht-color-nice-yellow-100);
    --ht-color-variant-warning-200: var(--ht-color-nice-yellow-200);
    --ht-color-variant-warning-300: var(--ht-color-nice-yellow-300);
    --ht-color-variant-warning-400: var(--ht-color-nice-yellow-400);
    --ht-color-variant-warning-500: var(--ht-color-nice-yellow-500);
    --ht-color-variant-warning-600: var(--ht-color-nice-yellow-600);
    --ht-color-variant-warning-700: var(--ht-color-nice-yellow-700);
    --ht-color-variant-warning-800: var(--ht-color-nice-yellow-800);
    --ht-color-variant-warning-900: var(--ht-color-nice-yellow-900);
    --ht-color-variant-warning-950: var(--ht-color-nice-yellow-950);
    --ht-color-variant-warning: var(--ht-color-nice-yellow);
}
