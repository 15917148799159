@use '../../styles/scss/global/index.scss' as *;

.bookmarks-widget {
    width: 60vw;
    height: 100px;
    margin: 0 auto;
    padding: 1em;
    // position: relative;

    .bookmark-items {
        width: 60%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 2em;
        margin: 0 auto;
    }

    .bookmark-item {
        margin: 1em;
        width: calc(100% / 6);
    }

    .bookmarker-toggler {
        &.bookmark-item {
            justify-content: flex-start;
            height: max-content;
            cursor: pointer;

            &:hover {
                .bookmark-item-icon {
                    background-color: white;
                    svg {
                        fill: var(--color-outline);
                    }
                }
            }

            .bookmark-item-icon {
                // background-color: white;
                @include set-transition((background), (0.4s), (ease));

                svg {
                    fill: var(--white);
                    width: 100% !important;
                    height: 100% !important;

                    @include set-transition((fill), (0.4s), (ease));
                }
            }

            &.hide {
                display: none;
            }
        }
    }
}
