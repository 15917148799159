@use '../../styles/scss/global/index.scss' as *;

.settings {
    .setting-block {
        --value-size: #{vw(68px)};
        --prefix-size: #{vw(18px)};
        --block-size-width: #{vw(80px)};
        --block-size-height: #{vw(45px)};

        display: inline-grid;
        place-items: center;
        place-content: center;
        min-width: var(--block-size-width);
        min-height: var(--block-size-height);
        padding: 2em;
        margin: 0.2em 0;
        margin-right: 1em;
        background: var(--primaryBlue);
        color: var(--white);

        text-align: center;

        .title {
            text-transform: capitalize;
        }

        .value {
            text-align: center;
            font-size: var(--value-size);
            span {
                display: inline-block;
                font-size: var(--prefix-size);
                margin-left: 0.2em;
            }
        }
    }
}

@media (max-width: $tablet) {
    .settings {
        .setting-block {
            --value-size: #{vw-tablet(68px)};
            --prefix-size: #{vw-tablet(18px)};
            --block-size-width: #{vw-tablet(200px)};
            --block-size-height: #{vw-tablet(200px)};
        }
    }
}

@media (max-width: $mobile) {
    .settings {
        .setting-block {
            --value-size: #{vw-mobile(68px)};
            --prefix-size: #{vw-mobile(18px)};
            --block-size-width: #{vw-mobile(100px)};
            --block-size-height: #{vw-mobile(100px)};
        }
    }
}
