@use '../../styles/scss/global/index.scss' as *;

.date-widget {
    --date-widget-width: max-content;
    --date-widget-height: #{vw(150px)};

    --time-size: var(--s4);
    --date-size: var(--s2);

    display: grid;
    place-content: center;
    align-content: flex-start;

    width: var(--date-widget-width);
    height: var(--date-widget-height);
    text-align: left;
    margin: auto;

    .widget {
        &-time-wrapper {
            font-size: var(--time-size);
            font-weight: 700;
            letter-spacing: 5px;
        }

        &-date-wrapper {
            font-size: var(--date-size);
            font-weight: 500;
            line-height: 1.3;
            text-transform: capitalize;
        }

        &-date-wrapper,
        &-time-wrapper {
            text-align: center;
            color: var(--white);
            @include drop-shadow();
        }
    }
}
