@use '../../styles/scss/global/' as *;

.settings {
    & &-choices {
        --settings-choice-size: #{vw(18px)};
        --choice-toggler-size: #{vw(24px)};

        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 60%;
        padding: 1.5em 2em;
        cursor: pointer;
        text-transform: capitalize;
        color: var(--white);
        background: var(--primaryBlue);
        font-weight: 500;
        font-size: var(--settings-switcher-size);
        border: 1px solid currentColor;
        margin: 0.2em 0;

        .choices {
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            .choice-field {
                margin: 0 1em;
                display: flex;
                align-items: center;

                &__label {
                    cursor: pointer;
                }

                &__input {
                    visibility: hidden;

                    &:checked {
                        & ~ .choice-field__toggler {
                            &::after {
                                background: var(--color-valid);
                            }
                        }
                    }
                }

                &__toggler {
                    display: inline-block;
                    position: relative;
                    width: var(--choice-toggler-size);
                    height: var(--choice-toggler-size);
                    background: white;
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;
                    cursor: pointer;

                    transition: all 0.2s ease;
                    -webkit-transition: all 0.2s ease;
                    -moz-transition: all 0.2s ease;
                    -ms-transition: all 0.2s ease;
                    -o-transition: all 0.2s ease;

                    &::after {
                        content: '';
                        width: 50%;
                        height: 50%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        -webkit-transform: translate(-50%, -50%);
                        -moz-transform: translate(-50%, -50%);
                        -ms-transform: translate(-50%, -50%);
                        -o-transform: translate(-50%, -50%);
                        background: var(--color-disabled);
                        border-radius: 50%;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        -ms-border-radius: 50%;
                        -o-border-radius: 50%;
                    }

                    &:hover {
                        box-shadow: 0 0 10px rgba(var(--color-disabled) 0.4);
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet) {
    .settings {
        & &-choices {
            --settings-choice-size: #{vw-tablet(18px)};
            --choice-toggler-size: #{vw-tablet(24px)};
        }
    }
}

@media (max-width: $mobile) {
    .settings {
        & &-choices {
            --settings-choice-size: #{vw-mobile(18px)};
            --choice-toggler-size: #{vw-mobile(24px)};
        }
    }
}
