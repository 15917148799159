@use '../../styles/scss/global/index.scss' as *;

.search-widget {
    --search-input-size: #{vw(20px)};
    --search-btn-size: #{vw(30px)};
    --search-bar-radius: #{vw(25px)};

    justify-self: center;
    align-self: flex-start;
    width: 100%;
    max-width: 60vw;
    width: 100%;
    height: 100%;
    @include drop-shadow();

    .search {
        &-form {
            width: 70%;
            height: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;

            .search-field {
                width: 80%;
                height: 100%;
                position: relative;

                &:active,
                &:focus,
                &:focus-within,
                &:focus-visible {
                    .field {
                        &__input {
                            border-color: var(--color-outline);
                            background-color: var(--white);
                        }
                        &__label {
                            svg {
                                fill: var(--color-outline);
                            }
                        }
                    }
                }

                .field {
                    &__error {
                        display: none;
                    }

                    &__label {
                        width: var(--search-btn-size);
                        height: var(--search-btn-size);
                        position: absolute;
                        top: 50%;
                        left: 5%;
                        transform: translate(-90%, -50%);
                        -webkit-transform: translate(-90%, -50%);
                        -moz-transform: translate(-90%, -50%);
                        -ms-transform: translate(-90%, -50%);
                        -o-transform: translate(-90%, -50%);

                        display: grid;
                        place-items: center;
                        margin-left: 0.5em;

                        svg {
                            fill: grey;
                            width: 100%;
                            height: 100%;

                            @include set-transition((fill), (0.4s));
                        }
                    }

                    &__input {
                        width: 100%;
                        height: 100%;
                        padding: 0.5em 1em 0.5em
                            calc(var(--search-btn-size) * 1.5);
                        font-size: var(--search-input-size);
                        background-color: #f5f5f5;
                        outline: none;
                        border: 2px solid grey;
                        border-radius: var(--search-bar-radius);
                        -webkit-border-radius: var(--search-bar-radius);
                        -moz-border-radius: var(--search-bar-radius);
                        -ms-border-radius: var(--search-bar-radius);
                        -o-border-radius: var(--search-bar-radius);

                        @include set-transition(
                            (border-color, background-color),
                            (0.4s, 0.4s),
                            (ease, ease)
                        );
                    }
                }
            }
        }
    }
}
