.adsWrapper {
    height: 100%;
    width: 100%;
    z-index: var(--z-index-popup);
}

.ad {
    --horizontal-ad-width: calc(var(--measure));
    --horizontal-ad-height: calc(var(--measure) / var(--scale-5));

    --square-ad-width: calc(var(--measure) / var(--scale-4));
    --square-ad-height: calc(var(--measure) / var(--scale-4));
}

@media (max-width: 1024px) {
    .horizontal {
        width: var(--horizontal-ad-width) !important;
        height: var(--horizontal-ad-height) !important;
    }

    .square {
        width: var(--square-ad-width) !important;
        height: var(--square-ad-height) !important;
    }
}
