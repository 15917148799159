.background {
    /* 
    TODO (hom): Investigate this gradient
  */
    background: linear-gradient(
            rgba(0, 0, 0, 0.48),
            rgba(0, 0, 0, 0) 40%,
            rgba(0, 0, 0, 0) 92%,
            rgba(0, 0, 0, 0.6) 100%
        )
        center center/cover no-repeat fixed;
    height: 100vh;
    width: 100vw;
    position: relative;
}

.background > div {
    animation-name: fade-in;
    animation-timing-function: cubic-bezier(0.46, 0.03, 0.52, 0.96);
    animation-duration: 0.5s;
    animation-duration: var(--transition-slow);
}

.background img {
    height: 100%;
    left: 0;
    object-fit: cover;
    opacity: var(--opacity-0);
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    transition: opacity calc(var(--transition-slow));
    will-change: opacity;
}

.background img.active {
    opacity: var(--opacity-100);
    transition-delay: 0.5s;
}

.artist {
    --artist-size: var(--s0);

    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    color: var(--white);
    flex-wrap: nowrap;
    font-family: proxima-nova, sans-serif;
    font-size: var(--artist-size);
    font-weight: var(--font-weight-medium);
    opacity: var(--opacity-85);
    text-align: left;
    white-space: nowrap;
}
.artist svg {
    font-size: var(--spacing-x-large);
}

.artist span {
    margin-left: var(--spacing-2x-small);
}

.artist a {
    font-weight: var(--font-weight-semibold);
    color: inherit;
    text-decoration: none;
}

@keyframes fade-in {
    from {
        opacity: var(--opacity-0);
    }

    to {
        opacity: var(--opacity-100);
    }
}
