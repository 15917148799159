@font-face {
    font-family: 'proxima-nova';
    font-weight: 100;
    font-style: normal;
    src: url('../../../assets/fonts/proxima/thin.otf');
}

@font-face {
    font-family: 'proxima-nova';
    font-weight: 300;
    font-style: normal;
    src: url('../../../assets/fonts/proxima/light.otf');
}

@font-face {
    font-family: 'proxima-nova';
    font-weight: 400;
    font-style: normal;
    src: url('../../../assets/fonts/proxima/regular.otf');
}

@font-face {
    font-family: 'proxima-nova';
    font-weight: 500;
    font-style: normal;
    src: url('../../../assets/fonts/proxima/medium.otf');
}

@font-face {
    font-family: 'proxima-nova';
    font-weight: 700;
    font-style: normal;
    src: url('../../../assets/fonts/proxima/bold.otf');
}

@font-face {
    font-family: 'proxima-nova';
    font-weight: 800;
    font-style: normal;
    src: url('../../../assets/fonts/proxima/extrabold.otf');
}

@font-face {
    font-family: 'proxima-nova';
    font-weight: 900;
    font-style: normal;
    src: url('../../../assets/fonts/proxima/black.otf');
}
