:root {
    --line-height-small: calc(0.8 * var(--ratio));
    --line-height: var(--ratio);
    --measure: 65ch;
    --ratio: 1.4;
}

:root {
    /*
    NOTE (hom): border-radiuses
   */
    --border-radius-circle: 50%;
    --border-radius-large: 0.5rem;
    --border-radius-larger: 1rem;
    --border-radius-medium: 0.25rem;
    --border-radius-pill: 9999px;
    --border-radius-small: 0.1875rem;

    /* 
    NOTE (hom): borders
  */
    --border-normal: 1.5px;
    --border-thick: var(--s-5);
    --border-thin: 1px;
    --border-thinner: 0.4px;

    /* 
    NOTE (hom): outlines
  */
    --focus-ring-alpha: 45%;
    --focus-ring-width: 4px;
    --focus-ring: 0 0 0 var(--focus-ring-width) var(--ht-color-bg-primary);

    /* 
    NOTE (hom): font-sizes
  */
    --font-size-2x-small: 0.625rem;
    --font-size-x-small: 0.75rem;
    --font-size-small: 0.875rem;
    --font-size-medium: 1rem;
    --font-size-large: 1.25rem;
    --font-size-x-large: 1.5rem;
    --font-size-2x-large: 2.25rem;
    --font-size-3x-large: 3rem;
    --font-size-4x-large: 4.5rem;

    /* 
    NOTE (hom): font-weights
  */
    --font-weight-light: 300;
    --font-weight-normal: 400;
    --font-weight-medium: 500;
    --font-weight-semibold: 600;
    --font-weight-bold: 700;
    --font-weight-bolder: 900;

    /* 
    NOTE (hom): letter-spacings
  */
    --letter-spacing-denser: -0.03em;
    --letter-spacing-dense: -0.015em;
    --letter-spacing-normal: normal;
    --letter-spacing-loose: 0.075em;
    --letter-spacing-looser: 0.15em;

    /* NOTE (hom): line-heights */
    --line-height-denser: 1;
    --line-height-dense: 1.4;
    --line-height-normal: 1.8;
    --line-height-loose: 2.2;
    --line-height-looser: 2.6;

    /* 
    NOTE (hom): opacities 
  */
    --opacity-0: 0;
    --opacity-10: 0.1;
    --opacity-20: 0.2;
    --opacity-25: 0.25;
    --opacity-30: 0.3;
    --opacity-35: 0.35;
    --opacity-40: 0.4;
    --opacity-45: 0.45;
    --opacity-50: 0.5;
    --opacity-55: 0.55;
    --opacity-60: 0.6;
    --opacity-65: 0.65;
    --opacity-70: 0.7;
    --opacity-75: 0.75;
    --opacity-80: 0.8;
    --opacity-85: 0.85;
    --opacity-90: 0.9;
    --opacity-95: 0.95;
    --opacity-100: 1;

    /* 
    NOTE (hom): font-sizes with modular scale
  */
    --s-1: calc(var(--s0) / var(--ratio));
    --s-2: calc(var(--s-1) / var(--ratio));
    --s-3: calc(var(--s-2) / var(--ratio));
    --s-4: calc(var(--s-3) / var(--ratio));
    --s-5: calc(var(--s-4) / var(--ratio));
    --s-6: calc(var(--s-5) / var(--ratio));
    --s-7: calc(var(--s-6) / var(--ratio));
    --s-8: calc(var(--s-7) / var(--ratio));
    --s-9: calc(var(--s-8) / var(--ratio));
    --s-10: calc(var(--s-9) / var(--ratio));
    --s0: 1rem;
    --s1: calc(var(--s0) * var(--ratio));
    --s2: calc(var(--s1) * var(--ratio));
    --s3: calc(var(--s2) * var(--ratio));
    --s4: calc(var(--s3) * var(--ratio));
    --s5: calc(var(--s4) * var(--ratio));
    --s6: calc(var(--s5) * var(--ratio));
    --s7: calc(var(--s6) * var(--ratio));
    --s8: calc(var(--s7) * var(--ratio));
    --s9: calc(var(--s8) * var(--ratio));
    --s10: calc(var(--s9) * var(--ratio));

    /* 
    NOTE (hom): scaling variables
  */
    --scale--1: calc(var(--scale-0) / var(--ratio));
    --scale--2: calc(var(--scale--1) / var(--ratio));
    --scale--3: calc(var(--scale--2) / var(--ratio));
    --scale--4: calc(var(--scale--3) / var(--ratio));
    --scale--5: calc(var(--scale--4) / var(--ratio));
    --scale-0: 1;
    --scale-1: calc(var(--scale-0) * var(--ratio));
    --scale-2: calc(var(--scale-1) * var(--ratio));
    --scale-3: calc(var(--scale-2) * var(--ratio));
    --scale-4: calc(var(--scale-3) * var(--ratio));
    --scale-5: calc(var(--scale-4) * var(--ratio));

    /*
    NOTE (hom): shadows
  */
    --shadow-large: 0 2px 8px hsl(240 3.8% 46.1% / 12%);
    --shadow-medium: 0 2px 4px hsl(240 3.8% 46.1% / 12%);
    --shadow-small: 0 1px 2px hsl(240 3.8% 46.1% / 12%);
    --shadow-x-large: 0 4px 16px hsl(240 3.8% 46.1% / 12%);
    --shadow-x-small: 0 1px 2px hsl(240 3.8% 46.1% / 6%);

    /* 
    NOTE (hom): spacings, paddings, margins
  */
    --spacing-5x-small: 0.05rem;
    --spacing-4x-small: 0.125rem;
    --spacing-3x-small: 0.175rem;
    --spacing-2x-small: 0.25rem;
    --spacing-x-small: 0.5rem;
    --spacing-small: 0.75rem;
    --spacing-medium: 1rem;
    --spacing-large: 1.25rem;
    --spacing-x-large: 1.75rem;
    --spacing-2x-large: 2.25rem;
    --spacing-3x-large: 3rem;
    --spacing-4x-large: 4.5rem;

    /* 
    NOTE (hom): transitions
  */
    --transition-fast: 150ms;
    --transition-faster: 50ms;
    --transition-normal: 250ms;
    --transition-slow: 500ms;
    --transition-slower: 1000ms;

    /* 
    NOTE (hom): z-indexes
  */
    --z-index-dropdown: 900;
    --z-index-menu: 700;
    --z-index-neg-dropdown: -900;
    --z-index-neg-menu: -700;
    --z-index-neg-popup: -800;
    --z-index-neg-start: -1;
    --z-index-neg-tooltip: -1000;
    --z-index-popup: 800;
    --z-index-start: 1;
    --z-index-tooltip: 1000;
}
