.wrapper {
    display: block;
    height: 100vh;
    left: 0;
    margin: 0;
    opacity: var(--opacity-0);
    padding: 0;
    position: fixed;
    top: 0;
    transform-origin: 0 0;
    transition: opacity var(--transition-normal),
        visibility var(--transition-normal);
    visibility: hidden;
    width: 100vw;
    z-index: var(--z-index-tooltip);
}

.wrapper.open {
    opacity: var(--opacity-100);
    visibility: visible;
}

.background {
    background-color: var(--ht-color-bg-primary);
    cursor: pointer;
    height: 100%;
    opacity: var(--opacity-40);
    width: 100%;
}

.content {
    background-color: var(--ht-color-text-primary);
    border-radius: var(--border-radius-small);
    box-shadow: var(--shadow-large);
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: transform var(--transition-normal);
    width: 25%;
    z-index: var(--z-index-tooltip);
}

.wrapper.open .content {
    transform: translateX(0%);
}

.header {
    background-color: var(--ht-color-bg-primary);
    color: var(--ht-color-text-primary);
    position: sticky;
    top: 0;
}

.title {
    font-size: var(--font-size-2x-large);
}

.header,
.body,
.footer {
    padding: var(--spacing-small) var(--spacing-large);
}

.body {
    height: 85vh;
    overflow-y: auto;
}
