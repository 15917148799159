stack-l.alert {
    --alert-variant-color: var(--ht-color-text-primary-800);
    background-color: var(--ht-color-text-primary-800);
    border-radius: var(--border-radius-medium);
    border-top: var(--border-thick) solid var(--alert-variant-color);
    box-shadow: var(--shadow-large);
    color: var(--ht-color-text-primary-50);
    font-size: 100%;
    font-weight: var(--font-weight-semibold);
    margin: 0 0 var(--spacing-large);
    max-width: calc(var(--measure) / var(--scale-2));
    min-height: var(--s4);
    min-width: calc(var(--measure) / (var(--scale-5) * var(--scale-3)));
    padding: var(--spacing-small) var(--spacing-x-large);
    text-transform: none;
    width: 100%;
}

.body {
    font-size: var(--s0);
}

stack-l.alert[data-variant='primary'] {
    --alert-variant-color: var(--ht-color-variant-primary);
}

stack-l.alert[data-variant='warning'] {
    --alert-variant-color: var(--ht-color-variant-warning);
}

stack-l.alert[data-variant='danger'] {
    --alert-variant-color: var(--ht-color-variant-danger);
}
