icon-l svg {
    /* original settings */
    /* height: 0.75em;
  height: 1cap;
  width: 0.75em;
  width: 1cap; */
    /* adapted to suit line-awesome icons */
    height: 1em;
    width: 1em;
    vertical-align: -0.15em;
}
