@use '../../styles/scss/global/' as *;

.settings {
    & &-switcher {
        --settings-switcher-size: #{vw(18px)};
        --toggler-width: #{vw(50px)};
        --toggler-height: #{vw(14px)};
        --toggler-switcher-size: #{vw(25px)};
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 60%;
        padding: 1.5em 2em;
        cursor: pointer;
        text-transform: capitalize;
        color: var(--white);
        background: var(--primaryBlue);
        font-weight: 500;
        font-size: var(--settings-switcher-size);
        border: 1px solid currentColor;
        margin: 0.2em 0;

        &__input {
            visibility: hidden;

            &:checked {
                & ~ .settings-switcher__toggler {
                    &::before {
                        background: var(--color-valid);
                        transform: translate(115%, -50%);
                        -webkit-transform: translate(115%, -50%);
                        -moz-transform: translate(115%, -50%);
                        -ms-transform: translate(115%, -50%);
                        -o-transform: translate(115%, -50%);
                    }
                }
            }
        }

        &__toggler {
            display: block;
            position: relative;
            background: var(--white);
            border-radius: 1rem;
            -webkit-border-radius: 1rem;
            -moz-border-radius: 1rem;
            -ms-border-radius: 1rem;
            -o-border-radius: 1rem;

            width: var(--toggler-width);
            height: var(--toggler-height);

            &::before {
                content: '';
                width: var(--toggler-switcher-size);
                height: var(--toggler-switcher-size);
                display: block;
                position: absolute;
                z-index: 2;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                top: 50%;
                left: -2%;
                background: var(--color-disabled);
                transform: translate(0, -50%);
                -webkit-transform: translate(0, -50%);
                -moz-transform: translate(0, -50%);
                -ms-transform: translate(0, -50%);
                -o-transform: translate(0, -50%);
                transition: transform 0.4s ease, color 0.4s ease, all 0.2s ease;
                -webkit-transition: transform 0.4s ease, color 0.4s ease,
                    all 0.2s ease;
                -moz-transition: transform 0.4s ease, color 0.4s ease,
                    all 0.2s ease;
                -ms-transition: transform 0.4s ease, color 0.4s ease,
                    all 0.2s ease;
                -o-transition: transform 0.4s ease, color 0.4s ease,
                    all 0.2s ease;
                &:hover {
                    box-shadow: 0 0 10px rgba(var(--color-disabled) 0.4);
                }
            }
        }
    }
}

@media (max-width: $tablet) {
    .settings {
        & &-switcher {
            --settings-switcher-size: #{vw-tablet(18px)};
            --toggler-width: #{vw-tablet(50px)};
            --toggler-height: #{vw-tablet(14px)};
            --toggler-switcher-size: #{vw-tablet(25px)};
        }
    }
}

@media (max-width: $mobile) {
    .settings {
        & &-switcher {
            --settings-switcher-size: #{vw-mobile(18px)};
            --toggler-width: #{vw-mobile(50px)};
            --toggler-height: #{vw-mobile(14px)};
            --toggler-switcher-size: #{vw-mobile(25px)};
        }
    }
}
