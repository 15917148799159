@use '../../styles/scss/global/index.scss' as *;

.amazon-ad-widget {
    --ad-width: #{vw(320px)};
    --ad-height: #{vw(250px)};

    width: var(--ad-width);
    height: var(--ad-height);

    iframe {
        width: 100%;
        height: 100%;
    }
}
