.wrapper {
    border-radius: var(--border-radius-large);
    filter: drop-shadow(var(--shadow-large));
    margin-top: var(--spacing-x-small);
    overflow-x: auto;
    padding: var(--spacing-x-small) var(--spacing-small);
    position: relative;
    transition: opacity var(--transition-normal);
}

.background {
    background-color: var(--ht-color-variant-neutral-800);
    border-radius: inherit;
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: var(--opacity-20);
    position: absolute;
    top: 0;
    width: 100%;
}

.wrapper .background ~ cluster-l {
    align-items: flex-end;
    flex: 1;
}

.wrapper:hover .background {
    opacity: var(--opacity-30);
}

.itemWrapper {
    --text-color: var(--ht-color-text-primary);
    color: var(--text-color);
}

.wrapper:hover .itemWrapper {
    --text-color: var(--ht-color-text-primary-950);
}
