.tooltip {
    position: relative;
    cursor: pointer;
}

.content {
    background-color: var(--ht-color-text-primary-700);
    border-radius: var(--border-radius-large);
    color: var(--ht-color-text-primary-100);
    filter: drop-shadow(var(--shadow-large));
    font-size: var(--s0);
    max-width: calc(var(--measure) / var(--scale-2));
    opacity: 0;
    padding: var(--spacing-x-small) var(--spacing-medium);
    position: absolute;
    right: 40%;
    top: var(--spacing-x-large);
    transition: opacity var(--transition-normal),
        visibility var(--transition-normal);
    visibility: hidden;
    width: max-content;
    will-change: opacity, visibility;
    z-index: var(--z-index-popup);
}

.content::before {
    border-bottom: var(--s-1) solid var(--ht-color-text-primary-700);
    border-left: var(--s-2) solid transparent;
    border-radius: var(--border-radius-small);
    border-right: var(--s-2) solid transparent;
    content: '';
    position: absolute;
    right: 5%;
    top: calc(var(--spacing-x-small) * -1);
    z-index: var(--z-index-tooltip);
}

.tooltip:hover .content {
    opacity: var(--opacity-100);
    visibility: visible;
}

.tooltip .badge {
    --spacing: var(--spacing-5x-small);
    font-size: var(--font-size-x-large);
    margin: 0;
    position: absolute;
    right: var(--spacing);
    top: calc(var(--spacing) * -1);
    z-index: var(--z-index-start);
}
