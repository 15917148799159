.socials {
    --social-icon-size: 2.6vmax;

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    width: 16%;

    &__link {
        list-style-type: none;
        color: var(--primaryBlue);

        a {
            color: inherit;
            font-size: var(--social-icon-size);
            line-height: 2;
            svg {
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
}
