sidebar-l {
    display: flex;
    flex-wrap: wrap;
}

sidebar-l > * {
    flex-grow: 1;
}

.side-drawer {
    display: flex;
    flex-wrap: wrap;
    gap: var(--s1);
}

.side-drawer > :first-child {
    flex-basis: 15rem;
    flex-grow: 1;
}

.side-drawer > :last-child {
    flex-basis: 0;
    flex-grow: 999;
    min-width: 70%;
}
