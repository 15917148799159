@use '../../styles/scss/global/index.scss' as *;

.settings {
    .setting-input {
        --title-size: #{vw(20px)};
        --value-size: #{vw(20px)};
        --prefix-size: #{vw(18px)};
        --block-size-width: #{vw(200px)};
        --block-size-height: #{vw(200px)};

        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 50%;
        max-height: 30px;
        padding: 2em;
        margin: 0.2em 0;
        margin-right: 1em;
        background: var(--primaryBlue);
        color: var(--white);

        .title {
            text-transform: capitalize;
            font-size: var(--title-size);
        }

        .value {
            color: var(--color-disabled);
        }
    }
}

@media (max-width: $tablet) {
    .settings {
        .setting-input {
            --title-size: #{vw-tablet(20px)};
            --value-size: #{vw-tablet(20px)};
            --prefix-size: #{vw-tablet(18px)};
            --block-size-width: #{vw-tablet(200px)};
            --block-size-height: #{vw-tablet(200px)};
        }
    }
}

@media (max-width: $mobile) {
    .settings {
        .setting-input {
            --title-size: #{vw-mobile(20px)};
            --value-size: #{vw-mobile(20px)};
            --prefix-size: #{vw-mobile(18px)};
            --block-size-width: #{vw-mobile(100px)};
            --block-size-height: #{vw-mobile(100px)};
        }
    }
}
