@use '../../styles/scss/global/' as *;

.settings-tabs {
    --settings-tab-size: var(--s1);

    background: var(--primaryBlue);

    width: 20%;
    height: 100vh;
    display: grid;
    place-items: center;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    box-shadow: 1px 0 15px rgba(0, 0, 0, 0.2);

    .navigation {
        align-self: flex-start;
        display: grid;
        align-items: center;
        width: 100%;
        margin-top: 3em;
        &-tab {
            list-style-type: none;
            font-size: var(--settings-tab-size);
            color: var(--white);
            margin: 0.5em 0;
            cursor: pointer;
            text-align: center;
            transition: all 0.4s ease;
            -webkit-transition: all 0.4s ease;
            -moz-transition: all 0.4s ease;
            -ms-transition: all 0.4s ease;
            -o-transition: all 0.4s ease;

            &.active {
                color: var(--primaryBlue);
                background: var(--white);
            }

            display: block;
            width: 100%;
            height: 100%;
            // color: inherit;
            text-decoration: none;

            a {
                display: grid;
                align-items: center;
                width: 100%;
                height: 100%;
                color: inherit;
                text-decoration: none;
                text-transform: capitalize;
                padding: 1em 0.5em;
            }
        }
    }

    .settings-logo {
        position: relative;
        width: 100%;
        padding: 2em 0;
        background: var(--white);
        display: grid;
        place-items: center;
        text-decoration: none;
        .logo__parent {
            position: static;
            transform: scale(0.6);
            -webkit-transform: scale(0.6);
            -moz-transform: scale(0.6);
            -ms-transform: scale(0.6);
            -o-transform: scale(0.6);
        }
    }
}

@media (max-width: $tablet) {
    .settings-tabs {
        --settings-tab-size: #{vw-tablet(30px)};
    }
}

@media (max-width: $mobile) {
    .settings-tabs {
        --settings-tab-size: #{vw-mobile(30px)};
    }
}
