@mixin btn {
    border: none;
    background: none;
    font-size: max(0.8rem, min(1.3vw, 1.3rem));
    color: white;
    filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, 0.4));
    cursor: pointer;
    margin: 0.2em 0;
    font-weight: 400;
    opacity: 0.9;

    &:hover {
        text-decoration: underline;
    }
}

.user {
    display: flex;
    align-items: center;
    width: max-content;

    line-height: 1.5;
    color: var(--white);
    margin: 0;

    a {
        text-decoration: none;
    }

    .money-counter,
    .tabs-counter,
    .logo,
    .notifications {
        color: white;
        font-size: max(1.2rem, min(1.8vw, 1.8rem));
        filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, 0.4));
        margin-right: 0.5em;

        &::after {
            content: '·';
            margin-left: 0.5em;
            filter: inherit;
            -webkit-filter: inherit;
        }

        display: flex;
        align-items: center;
    }

    .tabs-counter {
        .icon {
            margin: 0 0.2rem;
            text-align: center;

            width: 2rem;
            height: 2rem;
            svg {
                width: 80%;
                height: 80%;

                fill: var(--color-valid);
            }
        }
    }

    .logo {
        width: 2rem;
        height: 2rem;
        text-decoration: none;
        margin-right: 0;

        &::after {
            content: none;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, 0.2));
            -webkit-filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, 0.2));
        }
    }

    .user__dropdown {
        position: absolute;
        top: 125%;
        right: 5%;
        display: grid;
        justify-items: flex-end;
        align-items: flex-start;
        width: max-content;

        opacity: 0;
        visibility: hidden;
        background: var(--light-grey);
        border-radius: 3px;
        text-align: right;

        .action-btn {
            @include btn;

            display: flex;
            align-items: center;
            justify-content: flex-start;

            width: 100%;

            text-decoration: none;
            margin: 0;
            padding: 0.5em 1.5em;
            border: 1px solid currentColor;
            border-right: none;
            border-left: none;

            svg {
                margin-right: 0.5em;
            }

            &:hover {
                text-decoration: none;
                background-color: var(--color-outline);
            }
            transition: background-color 0.4s ease;
            -webkit-transition: background-color 0.4s ease;
            -moz-transition: background-color 0.4s ease;
            -ms-transition: background-color 0.4s ease;
            -o-transition: background-color 0.4s ease;
        }
        transition: opacity 0.4s ease, visibility 0.4s ease;
        -webkit-transition: opacity 0.4s ease, visibility 0.4s ease;
        -moz-transition: opacity 0.4s ease, visibility 0.4s ease;
        -ms-transition: opacity 0.4s ease, visibility 0.4s ease;
        -o-transition: opacity 0.4s ease, visibility 0.4s ease;

        transform: translate(0, 0);
        -webkit-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
    }

    .user__name {
        align-self: center;
        margin: 0 0.5em;
        margin-right: -0.5em;
        color: white;
        font-size: max(1.2rem, min(1.8vw, 1.8rem));
        filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, 0.4));
        cursor: pointer;
        &:hover {
            .user__dropdown {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
