.social-section {
    --valid: #4ede91;
    --error: #bb5320;
    --outline: #3d7d99;
    --red: #db4537;
    --white: #fff;

    display: grid;
    place-items: center;
    align-content: center;
    width: 100%;
    height: 100%;
    grid-row-gap: 1em;
    background-color: var(--white);

    .logo {
        &__parent {
            position: absolute;
            top: 0;
            left: 5%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            width: #{'max(5rem, min(10vw, 10rem))'};
            color: var(--primaryBlue);
            user-select: none;
            transform: scale(0.5);

            .logo {
                width: 90%;
                height: 90%;
                user-select: none;
            }

            .logo__text {
                font-size: #{'max(2rem, min(5.5vw, 6rem))'};
                margin: 0 0.1em;
                line-height: 0.7;
                user-select: none;
            }
        }
    }

    .social__btns {
        display: grid;
        grid-template-columns: 100%;
        grid-row-gap: 1.5em;

        a {
            color: inherit;
            text-decoration: none;
            display: block;
        }

        .signup-btn {
            display: flex;
            align-items: center;
            background-color: white;
            box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.4) !important;
            border: none !important;
            padding: 1em 1.5em !important;
            cursor: pointer;
            border-radius: 5px !important;
            font-size: 1vw !important;

            * {
                outline: none;
            }

            svg {
                margin-right: 0.2em;
                font-size: 2vw;
            }
        }

        .email-login-btn {
            width: 100%;
            display: flex;
            align-items: center;
            color: var(--white);
            background-color: var(--red);
            box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.4);
            border: none;
            padding: 1em 1.5em;
            cursor: pointer;
            border-radius: 5px;
            font-size: 1vw;

            svg {
                margin-right: 0.2em;
                font-size: 2vw;
            }
        }
    }
    .account-login__cta {
        margin: 1em 0;
        cursor: pointer;
        text-align: center;
        line-height: 1.5;
        a {
            color: var(--valid);
        }
    }
}
