body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body.newtab {
    overflow: hidden;
}

body:not(.newtab) .adsbygoogle {
    z-index: var(--z-index-neg-start);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

:root {
    --primaryBlue: #3d7d99;
    --white: #fff;
    --black: #292929;
    --color-valid: #4ede91;
    --color-error: #bb5320;
    --color-outline: #3d7d99;
    --color-disabled: #dbdbdb;
    --light-grey: #b1b1b1;
    --tablet: 1024px;
    --mobile: 640px;
}

body {
    background-color: var(--black);
}
