.underline::after {
    background: currentColor;
    content: '';
    display: block;
    height: 1px;
    width: 100%;
}

.title-tag {
    margin: 0.1em 0 0;
    padding: 0.2em 1em;
    background: var(--ht-color-bg-primary);
    color: var(--ht-color-text-primary);
    border-radius: 0.5rem;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
