.wrapper {
    color: var(--ht-color-text-primary);
    font-size: var(--s-4);
}

.wrapper svg {
    font-size: var(--s2);
}

.badge {
    width: var(--s-1);
    height: var(--s-1);
    border-radius: var(--border-radius-circle);
    background-color: var(--ht-color-bg-primary);
}

.button {
    cursor: pointer;

    background-color: var(--ht-color-bg-primary-400);
    color: var(--ht-color-text-primary);
    padding: var(--spacing-small);
    border-radius: var(--border-radius-medium);
    border: none;
    outline: none;
    box-shadow: none;
    appearance: none;
}

.noContent {
    font-size: var(--font-size-x-large);
}
