.wrapper {
    position: relative;
}

.wrapper .blockersDetected {
    display: none;
    font-size: var(--font-size-3x-large);
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: max-content;
    z-index: var(--z-index-popup);
}

.wrapper .blockersDetected.showWarning {
    display: block;
}

.wrapper .blockersDetected stack-l {
    background-color: var(--ht-color-text-primary-950);
    color: var(--ht-color-text-primary-50);
    min-width: max-content;
    text-align: left;
}

.wrapper .blockersDetected .icon {
    font-size: var(--s2);
    color: var(--ht-color-variant-warning);
}

.widgets {
    display: grid;
    grid-template-areas: 'dateWidget userWidget userWidget' 'bookmarksWidget . .' 'artistWidget artistWidget adsWidget';
    height: 100%;
    padding: var(--spacing-x-small) var(--spacing-x-large)
        var(--spacing-x-large);
    position: absolute;
    top: 0;
    width: 100%;
    z-index: var(--z-index-start);
}

.widgets.stacked {
    grid-template-areas:
        'dateWidget . userWidget'
        'bookmarksWidget . .' 'artistWidget artistWidget adsWidget';

    grid-auto-rows: calc(var(--measure) / var(--scale-5)) 1fr auto;
    grid-row-gap: var(--spacing-large);
}

.widgets.centeredBookmarks {
    grid-template-areas: 'dateWidget . userWidget' 'bookmarksWidget bookmarksWidget bookmarksWidget' 'artistWidget artistWidget adsWidget';
}

.widgets.centeredDate {
    grid-template-areas: 'bookmarksWidget . userWidget' 'dateWidget dateWidget dateWidget' 'artistWidget artistWidget adsWidget';
}

.widget {
    --size: calc(var(--s10));
    animation: fade-in var(--transition-slower);
    height: calc(var(--measure) / var(--scale-5));
    width: var(--size);
}

.dateWidget {
    grid-area: dateWidget;
    justify-self: flex-start;
    justify-content: flex-start;
}

.widgets.centeredBookmarks .dateWidget * {
    margin: 0;
}

.widgets.centeredDate .dateWidget {
    align-content: center;
    display: grid;
    justify-content: center;
    margin: 0 auto;
    align-self: center;
}

.widgets.centeredDate .dateWidget * {
    margin: 0 auto;
}

.userWidget {
    grid-area: userWidget;
    justify-self: flex-end;
}

.userWidget > div {
    justify-content: flex-end;
    width: 100%;
}

.artistWidget {
    align-content: flex-end;
    align-self: flex-end;
    display: grid;
    grid-area: artistWidget;
    justify-content: flex-start;
    justify-self: flex-start;
}

.adsWidget {
    align-items: flex-end;
    align-self: flex-end;
    grid-area: adsWidget;
    height: max-content;
    justify-self: flex-end;
    width: max-content;
}

.bookmarksWidget {
    grid-area: bookmarksWidget;
}

.widgets.centeredBookmarks .bookmarksWidget {
    justify-content: center;
    justify-self: center;
    margin-top: 0;
}

.widgets.centeredDate .bookmarksWidget {
    justify-self: flex-start;
    justify-content: flex-start;
    margin-top: 0;
}

@media (max-width: 1024px) {
    .wrapper .blockersDetected {
        top: 55%;
        width: 90%;
    }

    .wrapper .blockersDetected > stack-l {
        margin: 0 auto;
    }

    .widgets.stacked,
    .widgets.centeredBookmarks,
    .widgets.centeredDate {
        grid-row-gap: var(--s2);
        grid-template-areas: '. userWidget .' 'dateWidget dateWidget dateWidget' 'bookmarksWidget bookmarksWidget bookmarksWidget' 'artistWidget artistWidget artistWidget' 'adsWidget adsWidget adsWidget' !important;
        grid-template-rows: 0.3fr 0.8fr 2fr 1fr;
    }

    .widgets .widget {
        height: max-content;
        justify-content: center !important;
        justify-items: center !important;
        justify-self: center !important;
        width: 100%;
    }

    .widgets .widget.dateWidget * {
        margin: auto;
    }

    .widgets .widget.bookmarksWidget {
        margin-top: 0;
        width: 70%;
    }

    .artistWidget {
        align-self: flex-start;
        justify-self: center;
    }

    .userWidget > div {
        justify-content: center;
    }

    .adsWidget {
        height: unset;
        width: unset;
    }
}

@media (max-width: 769px) {
    .adsWidget {
        display: none;
    }
}

@media (max-width: 640px) {
    .widgets .widget.bookmarksWidget {
        width: 100%;
    }
}

@keyframes fade-in {
    from {
        opacity: var(--opacity-0);
    }
    to {
        opacity: var(--opacity-100);
    }
}
