@use "sass:list";

@mixin underline {
    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: currentColor;
    }
}

@mixin title-tag {
    margin: 0.1em 0 0;
    padding: 0.2em 1em;
    background: var(--primaryBlue);
    color: var(--white);
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -ms-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
}

@mixin drop-shadow() {
    filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, 0.4));
    -webkit-filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, 0.4));
}

@mixin circle-radius() {
    border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    -webkit-border-radius: 50%;
}

/*
example usage: 
  $properties: (opacity, transform);
  $durations: (0.4s, 0.4s);
  $methods: (linear, ease);
  @include set-transition($properties, $durations, $methods);
*/

@mixin set-transition($properties, $durations, $methods: (ease)) {
    $transition-value: '';
    @each $property in $properties {
        $propKey: list.index($properties, $property);
        $duration: list.nth($durations, $propKey);
        $method: '';
        @if (list.length($methods) > 1) {
            $method: list.nth($methods, $propKey);
        } @else {
            $method: list.nth($methods, 1);
        }

        @if ($transition-value == '') {
            $transition-value: #{$property $duration $method};
        } @else {
            $transition-value: #{$transition-value},
                #{$property $duration $method};
        }
    }

    transition: $transition-value;
    -webkit-transition: $transition-value;
    -moz-transition: $transition-value;
    -ms-transition: $transition-value;
    -o-transition: $transition-value;
}
