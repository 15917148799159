.icon {
    color: var(--ht-color-variant-primary);
    font-size: var(--font-size-x-large);
    font-weight: var(--font-weight-bold);
}

.wrapper {
    flex-wrap: nowrap;
    font-weight: var(--font-weight-bold);
    text-align: left;
}
