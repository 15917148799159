@font-face {
    font-family: 'itc-avant-garde-gothic';
    font-weight: 100;
    font-style: normal;
    src: url('../../../assets/fonts/itc-avant-garde-gothic/extra-light.ttf');
}

@font-face {
    font-family: 'itc-avant-garde-gothic';
    font-weight: 300;
    font-style: normal;
    src: url('../../../assets/fonts/itc-avant-garde-gothic/light.ttf');
}

@font-face {
    font-family: 'itc-avant-garde-gothic';
    font-weight: 400;
    font-style: normal;
    src: url('../../../assets/fonts/itc-avant-garde-gothic/regular.ttf');
}

@font-face {
    font-family: 'itc-avant-garde-gothic';
    font-weight: 500;
    font-style: normal;
    src: url('../../../assets/fonts/itc-avant-garde-gothic/medium.ttf');
}

@font-face {
    font-family: 'itc-avant-garde-gothic';
    font-weight: 700;
    font-style: normal;
    src: url('../../../assets/fonts/itc-avant-garde-gothic/bold.ttf');
}
