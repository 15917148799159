.bookmarker {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 2;

    display: none;
    place-items: center;
    place-content: center;

    width: 100vw;
    height: 100vh;

    background-color: rgba(127, 127, 127, 0.5);
    margin: 0;
    padding: 0;

    &.show {
        display: grid;
        margin: 0;
    }

    &-bg {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        z-index: -1;
    }

    &-close-btn {
        top: 5%;
        right: 5%;
        position: absolute;

        display: grid;
        place-items: center;
        width: 2rem;
        height: 2rem;

        background: var(--white);
        color: #292929;

        border: 1px solid currentColor;
        cursor: pointer;

        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
    }

    &-form {
        display: grid;
        place-items: center;
        grid-row-gap: 2.5em;
        background: white;
        color: black;
        width: 30%;
        min-height: 20vmax;
        padding: 5em;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);

        &__field {
            display: grid;
            align-items: center;
            grid-row-gap: 0.5em;
            position: relative;
            width: 100%;
            position: relative;

            .field {
                &__label {
                    text-align: left;
                    font-size: max(1rem, min(1.5vw, 1.5rem));
                    color: black;
                    width: 100%;
                }

                &__error {
                    text-align: left;
                    color: var(--color-error);
                    position: absolute;
                    top: 100%;
                    bottom: -30%;
                    margin-top: 0.2em;
                    font-size: max(0.7rem, min(1.1vw, 1.1rem));

                    & ~ .field__input {
                        color: var(--color-error);
                        border-color: var(--color-error);
                        opacity: 1;
                    }
                }

                &__input {
                    padding: 0.5em 0.2em;
                    outline: none;
                    font-size: max(0.8rem, min(1.2vw, 1.2rem));
                    border: 1px solid var(--color-outline);
                    border-bottom: 4px solid var(--color-outline);
                    transition: all 0.5s ease-in-out;
                    color: black;
                    opacity: 1;
                    transition: all 0.3s ease;
                    width: 100%;

                    &:active,
                    &:focus {
                        border-color: transparent;
                        border-bottom-color: var(--color-outline);
                    }

                    &::placeholder {
                        font-size: calc(100% - 2px);
                        color: black;
                        opacity: 0.8;
                    }
                }
            }
        }
    }

    &-actions-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: flex-end;

        & button {
            outline: none;
            border: none;
            color: white;
            cursor: pointer;
            font-size: max(0.6rem, min(1vw, 1rem));
            padding: 0.5em 1em;
            text-transform: capitalize;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
            opacity: 0.8;
            transition: opacity 0.3s ease;

            &.save {
                background-color: var(--color-outline);
            }

            &.cancel {
                background-color: grey;
            }

            &:hover,
            &:active,
            &:focus {
                opacity: 1;
            }
        }
    }
}
